import axios from 'services/axios'
import apiService from './api'

export const activityExport = async (
  activityIndices: string,
  entityId: string,
  vertical: string,
  filters?: string,
  datePeriod?: { start: string; end: string },
) => {
  const endpoint = 'activities/export'

  if (!activityIndices || !entityId) return

  const params = {
    activityIndices,
    entityId,
    vertical,
    filters,
    datePeriod,
  }

  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    throw new Error('Failed to fetch data')
  }
}
