import React, { useContext, useMemo } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import Paper from '@mui/material/Paper'
import WhiteBoxWrapper from '../WhiteBoxWrapper/WhiteBoxWrapper'
import columns from './columns'
import { allBusinessNameTableHeader, addAddressesHeader } from './utils'
import styles from './styles'
import BusinessDataSources from 'components/BusinessDataSources/BusinessDataSources'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'
import { VerticalContext } from 'store/verticals/Context'
import { IRegistry } from 'store/verticals/types'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'
import { formattedDate } from 'utils/formatters'
import Chip from '@mui/material/Chip'

const registerFoundIconUrl = require('./icons/registerFound.svg')
const noRegistryFoundUrl = require('./icons/noRegistryFound.svg')

const SellerRegistry = () => {
  const { verticalEntity } = useContext(VerticalContext)

  const rows = useMemo(() => {
    return (
      verticalEntity?.overviewDetails.registry?.map((item: IRegistry) => {
        const businessNamesArray = item.names.filter(el => el.key !== 'dba_name')
        const dbaObject = item.names.find(el => el.key === 'dba_name')

        return {
          id: item.ids.map((idObj, index) => `${idObj.key}--${idObj.value}--${index}`).join('-'),
          sellerRegId: item.ids,
          businessNames: businessNamesArray.map(el => el.value),
          dba: dbaObject?.value || 'N/A',
          addresses: item?.addresses?.map(addressObj => addressObj.value),
          status: item?.status === 'Registered' ? 'Active' : 'Inactive',
          startDate: formattedDate(item?.startDate, 'MMMM DD, YYYY'),
          endDate: formattedDate(item?.endDate, 'MMMM DD, YYYY'),
        }
      }) || []
    )
  }, [verticalEntity?.overviewDetails.registry])

  return (
    <TabScrolledContent>
      {verticalEntity?.overviewDetails.registry && verticalEntity?.overviewDetails.registry?.length > 0 ? (
        <WhiteBoxWrapper sx={{ mt: { md: '0px', lg: '0px', xl: '15px' } }}>
          <Stack flexDirection='row' alignItems='center' gap='8px'>
            <Box
              component='img'
              width={styles.iconStyles}
              height={styles.iconStyles}
              src={registerFoundIconUrl}
              alt='Register found'
            />
            <Typography sx={styles.title}>Registries Found</Typography>
          </Stack>
          <Paper sx={styles.paperContainer}>
            <DataGrid
              disableColumnSorting
              disableColumnMenu
              disableColumnResize
              disableColumnSelector
              disableColumnFilter
              rows={rows}
              columns={columns}
              sx={styles.dataGrid}
              hideFooter
            />
          </Paper>
        </WhiteBoxWrapper>
      ) : (
        <WhiteBoxWrapper>
          <Stack flexDirection='row' alignItems='center' justifyContent='center' gap='8px'>
            <Box component='img' width={32} height={32} src={noRegistryFoundUrl} alt='No Register found' />
            <Typography sx={styles.title}>No Registry Found</Typography>
          </Stack>
        </WhiteBoxWrapper>
      )}
      <WhiteBoxWrapper sx={{ mt: '15px' }}>
        <Stack flexDirection='row' alignItems='flex-start' gap='15px'>
          <Stack flex={1} sx={{ p: '0 8px 0 8px' }}>
            <Typography sx={styles.title}>ALL BUSINESS Names / DBA</Typography>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              {allBusinessNameTableHeader.map(name => (
                <Typography key={name} sx={styles.subTitle}>
                  {name}
                </Typography>
              ))}
            </Stack>
            {(verticalEntity?.overviewDetails?.businessNames || []).map(source => (
              <Stack
                key={source.name}
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
                sx={styles.namesCell}
              >
                <Box sx={{ ...styles.tableCell, display: 'flex', gap: '8px', alignItems: 'center' }}>
                  {source.name}
                  {/* {source.isRelatedCompany && (
                    <Chip sx={styles.entityNamesBadge} label='Related company' size='small' color='primary' />
                  )} */}
                </Box>
                <Stack>
                  <BusinessDataSources
                    dataSources={source.sources.map(dataSource => dataSource.platform)}
                    urls={source.sources.map(dataSource => dataSource.uri)}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Stack flex={1} sx={{ p: '0 8px 0 8px' }}>
            <Typography sx={styles.title}>All Addresses</Typography>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              {addAddressesHeader.map(name => (
                <Typography key={name} sx={styles.subTitle}>
                  {name}
                </Typography>
              ))}
            </Stack>
            {verticalEntity?.overviewDetails.locations?.map(location =>
              location.businesses.map(business => (
                <Stack
                  key={business.address}
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={styles.namesCell}
                >
                  <Stack sx={styles.tableCell}>{business.address}</Stack>
                  <Stack>
                    <BusinessDataSources
                      dataSources={business.sources.map(dataSource => dataSource.platform)}
                      urls={business.sources.map(dataSource => normalizeUrlProtocol(dataSource?.uri))}
                    />
                  </Stack>
                </Stack>
              )),
            )}
          </Stack>
        </Stack>
      </WhiteBoxWrapper>
    </TabScrolledContent>
  )
}

export default SellerRegistry
