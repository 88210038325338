import React, { useContext, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Content from '../Content/Content'
import VerticalDetailsContextProvider from 'store/verticalsDetails/Context'
import Details from 'containers/VerticalPage/ecommerce-extended/Details/Details'
import { CurrentZone } from 'utils/types'
import { useSelector } from 'react-redux'
import { selectCurrentZone } from 'store/global'
import { changeCurrentZoneIndexesFunc } from 'containers/VerticalPage/common/utils'
import styles from './styles'
import { VerticalContext } from 'store/verticals/Context'

const VerticalPage = (props: { changeCurrentZoneIndexes: changeCurrentZoneIndexesFunc }) => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const { verticalEntity } = useContext(VerticalContext)

  useEffect(() => {
    props.changeCurrentZoneIndexes({
      baseIndices: currentZone?.ecommerceExtendedIndices || null,
      activityIndices: currentZone?.ecommerceExtendedActivityIndices || null,
      employeeIndices: currentZone?.ecommerceExtendedEmployeesIndices || null,
    })
  }, [currentZone])

  return (
    <Stack sx={styles.container}>
      <Breadcrumbs
        containerSx={styles.breadcrumbsStyles}
        data={[
          { label: 'Home', href: '/' },
          { label: 'Ecommerce', href: '/ecommerce-extended' },
          { label: verticalEntity?.entityDetails.name || 'N/A' },
        ]}
      />
      <Content />
      <VerticalDetailsContextProvider>
        <Details />
      </VerticalDetailsContextProvider>
    </Stack>
  )
}

export default VerticalPage
