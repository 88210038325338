type InputData = Array<Record<string, any>>

export function jsonToCsv(items: InputData, headers: Array<string>): string {
  if (items.length === 0) {
    return ''
  }

  const headerKeys = Object.keys(items[0])

  const csvRows = [
    headers.join(','),
    ...items.map(item => {
      return headerKeys
        .map(key => {
          const value = item[key]
          return typeof value === 'string' && value.includes(',') ? `"${value.replace(/"/g, '""')}"` : value
        })
        .join(',')
    }),
  ]

  return csvRows.join('\n')
}
