import Loader from 'components/Loader/Loader'
import React, { useEffect } from 'react'
import { fetchLoginUrl } from './utils'

const LoginPage = () => {
  useEffect(() => {
    fetchLoginUrl
      .then(res => {
        window.location.href = res.data.url
      })
      .catch(err => {
        console.error('err', err)
      })
  }, [])

  return <Loader type='globalLoading' message={''} />
}

export default LoginPage
