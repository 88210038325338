import { defineMessages } from 'react-intl'

const scope = 'app.components.BusinessesSummary'

export default defineMessages({
  entities: {
    total: {
      id: `${scope}.entities.total`,
      defaultMessage: 'Total',
    },
    nonFiler: {
      id: `${scope}.entities.nonFiler`,
      defaultMessage: 'Non Filer',
    },
    underReport: {
      id: `${scope}.entities.underReport`,
      defaultMessage: 'Under Report',
    },
  },
  incomes: {
    profitLoss: {
      id: `${scope}.incomes.profitLoss`,
      defaultMessage: 'Gain/Loss (USD)',
    },
    estimated: {
      id: `${scope}.incomes.estimated`,
      defaultMessage: 'Estimated',
    },
    documented: {
      id: `${scope}.incomes.documented`,
      defaultMessage: 'Documented',
    },
    reported: {
      id: `${scope}.incomes.reported`,
      defaultMessage: 'Reported',
    },
    untaxed: {
      id: `${scope}.incomes.untaxed`,
      defaultMessage: 'Unreported',
    },
    total: {
      id: `${scope}.incomes.total`,
      defaultMessage: 'Total',
    },
  },
})
