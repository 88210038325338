import React, { FC, ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserRoles, fetchTransmissions, configureMap, configurePlatform, configureStaticFiles } from 'store/global'
import Loader from 'components/Loader/Loader'
import { fetchCurrentUser, selectCurrentUser } from 'store/global'

interface IAuthWrapper {
  children: ReactNode
}

const AuthWrapper: FC<IAuthWrapper> = ({ children }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    if (Object.keys(currentUser || {}).length !== 0) {
      dispatch(fetchUserRoles())
      dispatch(fetchTransmissions())
      dispatch(configureMap())
      dispatch(configurePlatform())
      dispatch(configureStaticFiles())
    }
  }, [currentUser, dispatch])

  useEffect(() => {
    dispatch(fetchCurrentUser())
  }, [dispatch])

  return <>{Object.keys(currentUser).length === 0 ? <Loader type='globalLoading' message={''} /> : children}</>
}

export default AuthWrapper
