import axios from 'axios'
import toCamelCaseKeys from 'camelcase-keys'
import history from 'utils/history'

class AxiosService {
  constructor() {
    axios.defaults.baseURL = window?._env_?.API_URL || process.env.API_URL
    axios.defaults.params = axios.defaults.params || {}
    axios.defaults.withCredentials = true

    axios.interceptors.request.use(
      config => {
        config.withCredentials = true
        return config
      },
      error => Promise.reject(error),
    )

    axios.interceptors.response.use(this.ensureKeysCase(toCamelCaseKeys), undefined)
    axios.interceptors.response.use(undefined, this.errorHandler)
  }

  ensureKeysCase(handler) {
    const forbiddenUrls = ['rentals/hostsAndListingsLocations']

    return config => {
      if (forbiddenUrls.includes(config.config.url)) return config
      if (config.data && !config.config.skipKeysConversion) {
        config.data = handler(config.data, { deep: true })
      }
      return config
    }
  }

  errorHandler(error) {
    const isAxiosError = ![typeof error, typeof error.response, typeof error.config].includes('undefined')

    if (isAxiosError && error.response?.status === 401) {
      console.error('[Request Interceptor] Unauthorized Error | Redirecting to login page')

      history.push('/login')
      return Promise.reject(error)
    }

    console.error(`[Request Interceptor] ${isAxiosError ? 'API' : 'Non-API'} Error | `, error)
    return Promise.reject(error)
  }

  request(config, isCrashable = true, isErrorable = true) {
    return axios({
      ...config,
      isCrashable,
      isErrorable,
      url: `/api/${config.url}`,
    })
  }

  get(url, config) {
    return axios.get(`/api/${url}`, config)
  }

  post(url, data, config) {
    return axios.post(`/api/${url}`, data, config)
  }

  put(url, data, config) {
    return axios.put(`/api/${url}`, data, config)
  }

  delete(url, config) {
    return axios.delete(`/api/${url}`, config)
  }
}

export default new AxiosService()
