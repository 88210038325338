import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VerticalContext } from 'store/verticals/Context'
import GenericText from 'components/common/GenericText'
import VerticalBAHeading from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBAHeading'
import VerticalBARevenueSource from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenueSource'
import VerticalBARevenueSourceDetails from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenueSourceDetails'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import { VerticalBusinessActivityLoader, VerticalTabsHeadingLoader } from 'containers/VerticalPage/common/Loaders'
import ActivityDropdown from 'containers/VerticalPage/common/ActivityDropdown/ActivityDropdown'
import GenericHeading from 'components/common/GenericHeading'
import DatePicker from 'components/common/DatePicker'
import { getHeadingValues, getDefaultDateValues } from 'containers/VerticalPage/common/utils'
import { VerticalsDetailsContext } from 'store/verticalsDetails/Context'
import { useDebounce } from 'hooks/useDebounce'
import { Waypoint } from 'react-waypoint'
import Loader from 'components/Loader/Loader'
import { useDocumentActivitiesQuery } from 'containers/VerticalPage/common/useDocumentActivitiesQuery'
import { selectCurrentZone } from 'store/global'
import type { CurrentZone } from 'utils/types'
import { usePeriodSetter } from 'containers/VerticalPage/common/hooks'
import styles from 'containers/VerticalPage/common/styles.scss'
import VerticalBARevenueRange from '../../common/VerticalBusinessActivity/VerticalBARevenueRange'
import ActivityExportButton from 'containers/VerticalPage/common/ActivityExportButton/ActivityExportButton'
import { onActivityCSVExport } from 'utils/activities'

const calendarIconUrl = require('components/common/images/calendar.svg')

const periodFormat = 'MMM. yyyy'
const periodPopperPlacement = 'bottom-end'
const PAGE_SIZE = 100

const BusinessActivity: FC = () => {
  const { getVerticalDocumentActivityStats } = useContext(VerticalContext)
  const { detailsFilters, submittedDropdownOptions } = useContext(VerticalsDetailsContext)
  const theme = useTheme()
  const defaultDateValues = getDefaultDateValues()

  const { params: { vertical = '' } = {} } = useRouteMatch<{ vertical: string }>('/vertical/:vertical') || {}

  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const headingValues = useMemo(() => getHeadingValues(submittedDropdownOptions), [submittedDropdownOptions])
  const { period: datePeriod, handlePeriodChange: handleBusinessActivityPeriodChange } =
    usePeriodSetter(defaultDateValues)

  const { entityId } = useParams<{ entityId: string }>()

  const datePeriodDebounced = useDebounce(datePeriod, 1000)

  const { verticalDocumentActivity, isFetching, isLoading, fetchNextPage } = useDocumentActivitiesQuery({
    entityId,
    detailsFilters,
    zoneActivityIndices: currentZone.serviceProvidersActivityIndices,
    currentPeriod: datePeriodDebounced,
    pageSize: PAGE_SIZE,
    activityType: undefined,
    sort: [],
  })

  useEffect(() => {
    if (!entityId || !currentZone) return
    fetchDocumentActivityStats()
  }, [entityId, datePeriodDebounced, currentZone, datePeriod, getVerticalDocumentActivityStats])

  const fetchDocumentActivityStats = async () => {
    await getVerticalDocumentActivityStats(
      entityId,
      currentZone.serviceProvidersActivityIndices,
      currentZone.serviceProvidersIndices,
    )
  }

  const handleEnterWaypoint = async () => fetchNextPage()

  const shouldRenderWaypoint = useCallback(
    (index: number) => {
      if (!verticalDocumentActivity) return false
      const hasMoreToFetch = verticalDocumentActivity.length >= PAGE_SIZE
      const isAtLastFetchedItem = index === verticalDocumentActivity.length - 1
      return hasMoreToFetch && isAtLastFetchedItem
    },
    [verticalDocumentActivity],
  )
  const handleExport = () => {
    const formattedFilters = JSON.stringify([
      ...submittedDropdownOptions.filter(item => item.isChecked).map(item => ({ revenueSource: item.revenueSource })),
    ])

    onActivityCSVExport(
      currentZone.serviceProvidersActivityIndices,
      entityId,
      vertical,
      formattedFilters,
      datePeriod,
      'ServiceProvidersActivity.csv',
    )
  }

  return (
    <Box
      sx={{
        bgcolor: 'white',
        gridRow: '2 / 3',
        overflow: 'hidden',
        display: 'grid',
        padding: '16px 0px 8px 12px',
        borderRadius: '16px',
      }}
    >
      {isLoading ? (
        <VerticalTabsHeadingLoader />
      ) : (
        <VerticalBAHeading
          customSx={{
            paddingInlineStart: '12px',
            paddingInlineEnd: '12px',
            pb: '3px',
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={{ lg: 1, xxxl: 3 }}>
              <GenericHeading
                sx={{
                  fontSize: { md: 14, lg: 16, xl: 18, xxl: 34, xxxl: 44 },
                  fontWeight: 600,
                }}
              >
                {headingValues.text}
              </GenericHeading>
              <Stack
                direction='row'
                alignItems='center'
                sx={{
                  fontFamily: 'Dosis',
                  fontSize: { md: 14, lg: 16, xl: 18, xxl: 34, xxxl: 44 },
                  fontWeight: 500,
                }}
                className={styles.headingValuesContainer}
              >
                {`${headingValues.activities} ACTIVITIES`}
              </Stack>
              <ActivityDropdown />
            </Stack>
            <div className={styles.actions}>
              <ActivityExportButton onExport={handleExport} />
              <DatePicker
                periodStart={datePeriod.start}
                periodEnd={datePeriod.end}
                handlePeriodChange={handleBusinessActivityPeriodChange}
                format={periodFormat}
                popperPlacement={periodPopperPlacement}
                isCharts
                iconUrl={calendarIconUrl}
              />
            </div>
          </Stack>
        </VerticalBAHeading>
      )}

      <Stack
        sx={{
          overflow: isLoading ? 'hidden' : 'auto',
          pt: '4px',
          ...theme.mixins.customScrollBar(),
        }}
        pl='12px'
      >
        {isLoading ? (
          <>
            <VerticalBusinessActivityLoader />
            <VerticalBusinessActivityLoader />
            <VerticalBusinessActivityLoader />
          </>
        ) : verticalDocumentActivity?.length ? (
          <Box position='relative'>
            {verticalDocumentActivity?.map((activity, index) => (
              <Stack
                key={activity.id}
                direction='row'
                sx={{
                  borderBlockEnd: index === verticalDocumentActivity.length - 1 ? 'none' : '1px solid #DFDFDF',
                }}
              >
                <Box
                  minWidth={{ md: 150, lg: 200, xl: 200, xxl: 300, xxxl: 400 }}
                  pl={{ md: 0, lg: 1, xl: 2, xxl: 2, xxxl: 2 }}
                  flexBasis={1}
                >
                  <VerticalBARevenueSource
                    user={activity.activityUser}
                    userPhoto={activity.activityUserPhoto}
                    activityType={activity.revenueSource}
                    activityUrl={activity.activityUrl}
                    platform={activity.activitySource}
                    activityDate={activity.activityDate}
                  />
                </Box>

                <Divider orientation='vertical' flexItem />

                <Box flex={1}>
                  <VerticalBARevenueSourceDetails
                    headline={activity?.activityHeadline}
                    text={activity.activityText}
                    photoUrl={activity.activityPhoto}
                  />
                </Box>

                <Divider orientation='vertical' flexItem />

                <Box
                  minWidth={{ lg: 150, xxxl: 220 }}
                  ml={{ lg: 2, xxxl: 4 }}
                  flexBasis={1}
                  sx={{
                    [theme.breakpoints.down('xl')]: {
                      minWidth: '150px',
                      ml: 2,
                    },
                  }}
                >
                  <VerticalBARevenueRange revenue={activity.revenue} revenueMax={activity.revenueMax} />
                </Box>
                {shouldRenderWaypoint(index) && <Waypoint onEnter={handleEnterWaypoint} />}
              </Stack>
            ))}
            {isFetching && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '20px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <Loader type='scrolling' />
              </Box>
            )}
          </Box>
        ) : (
          <GenericText
            sx={{
              fontSize: { lg: 24, xxxl: 48 },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              [theme.breakpoints.down('xl')]: {
                fontSize: '24px',
              },
            }}
          >
            No Activity
          </GenericText>
        )}
      </Stack>
    </Box>
  )
}

export default BusinessActivity
