import { activityExport } from 'services/activitiesApi'
import { downloadCSV } from 'utils/downloadCSV'
import { jsonToCsv } from './JsonToCsvConverter'

export const onActivityCSVExport = async (
  activityIndices: string,
  entityId: string,
  vertical: string,
  filters?: string,
  datePeriod?: { start: string; end: string },
  csvFileName?: string,
): Promise<void> => {
  try {
    const { activities, headers } = await activityExport(activityIndices, entityId, vertical, filters, datePeriod)
    const activitiesCSV = jsonToCsv(activities, headers)

    downloadCSV(activitiesCSV, csvFileName || 'ActivityExport.csv')
  } catch (error) {
    console.error('Error exporting CSV:', error)
    throw error
  }
}
