import React from 'react'
import { FormattedNumber, injectIntl } from 'react-intl'
import cx from 'classnames'
import PropTypes from 'prop-types'
import SummarySection from 'components/SummarySection'
import { PieChart, PieChartLegends } from 'components/PieChart/index'
import TitledContent from 'components/TitledContent'
import ConditionalWrapper from 'components/ConditionalWrapper'
import Income from 'components/Income'
import Icon from 'components/Icon'
import NoData from 'components/NoData'
import BoxDecoration from 'components/BoxDecoration'
import messages from './messages'
import styles from './BusinessesSummary.scss'

const ENTITIES = [
  {
    key: 'total',
  },
  {
    key: 'nonFiler',
    icon: 'nonFiler',
  },
  {
    key: 'underReport',
    icon: 'underReport',
  },
]

const INCOME_TYPES_ESTIMATED = ['estimated', 'reported', 'untaxed']
const INCOME_TYPES_DOCUMENTED = ['documented', 'reported', 'untaxed']
const INCOME_TYPES_TOTAL = ['total', 'reported', 'untaxed']

const INCOME_TYPES_PROFITLOSS = ['profitLoss', 'reported', 'untaxed']

const topIndustriesLegendsColors = ['hsl(149, 70%, 50%)', 'hsl(29, 70%, 50%)', 'hsl(27, 70%, 50%)', 'hsl(11, 70%, 50%)']

function BusinessesSummary({ intl, isLoading = false, summary, isNFT = false, isInfluencers = false }) {
  const { entityCounts, incomeCounts, topIndustries = [], industriesCount } = summary

  const income_types = incomeCounts?.profitLoss
    ? INCOME_TYPES_PROFITLOSS
    : isNFT
      ? INCOME_TYPES_DOCUMENTED
      : isInfluencers
        ? INCOME_TYPES_TOTAL
        : INCOME_TYPES_ESTIMATED

  const divider = industriesCount || entityCounts?.total

  const topIndustriesLegends = Array.isArray(topIndustries)
    ? (topIndustries || [])?.slice(0, 4)?.map(([id, value], index) => ({
        id,
        value: value / divider,
        label: id,
        color: topIndustriesLegendsColors[index],
      }))
    : []

  return (
    <div className={styles.container}>
      <BoxDecoration />
      <SummarySection name='entities' isLoading={isLoading}>
        {ENTITIES.map(({ key = '', icon = '' }) => (
          <TitledContent
            key={key}
            title={intl.formatMessage(messages.entities[key])}
            className={cx(styles.entity, styles[key])}
            titleClassName={styles.sectionTitle}
          >
            <ConditionalWrapper
              condition={!!icon && !!entityCounts?.[key]}
              wrapper={children => (
                <Icon name={icon} direction='right' size='regular' className={styles.entityIcon}>
                  {children}
                </Icon>
              )}
            >
              {entityCounts?.[key] ? (
                <span className={cx({ [styles.hidden]: !entityCounts[key] })}>
                  <FormattedNumber value={entityCounts[key] || 0} />
                </span>
              ) : (
                <NoData />
              )}
            </ConditionalWrapper>
          </TitledContent>
        ))}
      </SummarySection>
      <SummarySection name='income' isLoading={isLoading}>
        {income_types.map(key => (
          <TitledContent
            key={key}
            title={intl.formatMessage(messages.incomes[key])}
            className={styles.income}
            titleClassName={styles.sectionTitle}
          >
            {typeof incomeCounts?.[key] === 'number' ? <Income value={incomeCounts[key] || 0} /> : <NoData />}
          </TitledContent>
        ))}
      </SummarySection>
      <SummarySection
        name={isNFT ? 'platforms' : isInfluencers ? 'revenueSources' : 'industries'}
        isLoading={isLoading}
      >
        <PieChart data={topIndustriesLegends} className={styles.pie} innerClassName={styles.totalResolvers} />
        <PieChartLegends legends={topIndustriesLegends} />
      </SummarySection>
    </div>
  )
}

BusinessesSummary.propTypes = {
  isLoading: PropTypes.bool,
}

export default injectIntl(BusinessesSummary)
