import React, { useMemo, useState, useEffect } from 'react'
import Alert from '@mui/material/Alert'
import { selectCurrentZone } from '../../store/global'
import BusinessesSummary from './BusinessSummary/BusinessSummary'
import GridLoader from 'components/GridLoader/GridLoader'
import FatcaTable from 'components/fatca/FatcaTable/FatcaTable'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { CurrentZone } from 'utils/types'
import { FatcaEntityForTable } from './types'

import { useVerticalEntitiesQuery } from 'hooks/useVerticalEntitiesQuery'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './FatcaTablePage.module.scss'
import { getUrlPageValues, removeKeywordSuffix } from '../VerticalPage/common/utils'
import { IFatcaOverviewData } from './BusinessSummary/utils'
import { useFatcaOverviewQuery } from 'hooks/useFatcaOverviewQuery'
import { Filter, FilterAdditionalData } from 'components/FilterDrawer/FilterDrawer.config'
import { MRT_ColumnDef } from 'material-react-table'
import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import ActiveFilters from 'components/ActiveFilters/ActiveFilters'

import { filtersInitialState } from './FatcaTablePage.config'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { useEmptyColumnsQuery } from 'hooks/useEmptyColumnsQuery'

const csvFileName = 'Fatca'

const FatcaTablePage = (): JSX.Element => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<FatcaEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const [filterAdditionalData, setFilterAdditionalData] = useState<FilterAdditionalData[]>([])

  const allColumns = useMemo(() => mergedColumns?.flatMap(column => column?.columns ?? []), [mergedColumns]) || []

  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()

  const { data: emptyColumns } = useEmptyColumnsQuery({
    verticalIndices: currentZone?.fatcaIndices,
    currentQueryKey: 'fatca-entities-key',
    keys: removeKeywordSuffix(allColumns.map(el => el.id || '').filter(key => Boolean(key))),
  })

  // Fetch overview data
  const { data: verticalOverviewData } = useFatcaOverviewQuery<IFatcaOverviewData>({
    verticalIndices: currentZone?.fatcaIndices,
    currentQueryKey: 'fatca-overview-key',
    searchValue: searchValueDebounced,
    filters: activeFilters,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading, isError } =
    useVerticalEntitiesQuery<FatcaEntityForTable>({
      verticalIndices: currentZone?.fatcaIndices,
      currentQueryKey: 'fatca-entities-key',
      searchValue: searchValueDebounced,
      sorting,
      filters: activeFilters,
    })

  // Fetch filter options data
  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.fatcaIndices,
    currentQueryKey: 'fatca-filter-options-key',
    filtersInitialState,
  })

  useEffect(() => {
    if (verticalOverviewData && filterAdditionalData.length === 0) {
      setFilterAdditionalData([
        {
          name: 'totals.unreportedRevenue',
          minLimit: verticalOverviewData.totalUnreportedEarningRange.min,
          maxLimit: verticalOverviewData.totalUnreportedEarningRange.max,
        },
      ])
    }
  }, [verticalOverviewData, filterAdditionalData])

  return (
    <div className={styles.container}>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary} id={'businessSummary'}>
            <BusinessesSummary overviewData={verticalOverviewData} />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalOverviewData ? (verticalEntities?.length ?? null) : null}
                totalCount={
                  verticalOverviewData && verticalEntities?.length ? verticalOverviewData.totalEntities : null
                }
                zoneEndpoint={currentZone?.fatcaIndices}
                csvFileName={csvFileName}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                type={getUrlPageValues()[0]}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={allColumns}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
                filterAdditionalData={filterAdditionalData}
                emptyColumns={emptyColumns}
              />
              {isLoading ? (
                <GridLoader />
              ) : isError ? (
                <Alert severity='error' sx={{ fontFamily: 'Source Sans Pro', fontSize: '1rem', alignItems: 'center' }}>
                  Something went wrong
                </Alert>
              ) : (
                <>
                  <ActiveFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters} styles={styles} />
                  <section className={styles.grid}>
                    <FatcaTable
                      isFetching={isFetching}
                      fetchNextPage={fetchNextPage}
                      totalFetched={verticalEntities?.length || 0}
                      totalDBRowCount={verticalEntities?.length ? (verticalOverviewData?.totalEntities ?? 0) : 0}
                      verticalEntities={verticalEntities}
                      sorting={sorting}
                      setSorting={setSorting}
                      setMergedColumns={setMergedColumns}
                      selectedColumns={selectedColumns}
                      allColumns={allColumns}
                    />
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default FatcaTablePage
