import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Header from 'components/Header'
import Navigation from 'components/Navigation'
import SettingsModal from 'components/SettingsModal'
import PlatformsModal from 'components/PlatformsModal'
import Loader from 'components/Loader'
import Alerts from 'containers/Alerts'
import Preview from 'containers/Preview/Loadable'
import ErrorPage from 'containers/ErrorPage/Loadable'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import DashboardPage from 'containers/DashboardPage/Loadable'
import BusinessesPage from 'containers/BusinessesPage/Loadable'
import RentalsPage from 'containers/Rentals/'
import OrganizationsPage from 'containers/OrganizationsPage/Loadable'
import ProfilePage from 'containers/ProfilePage/Loadable'
import BusinessPage from 'containers/BusinessPage/Loadable'
import InfluencersTablePage from 'containers/InfluencersTablePage/InfluencersTablePage'
import EcommerceTablePage from 'containers/EcommerceTablePage/EcommerceTablePage'
import FreelancersTablePage from 'containers/FreelancersTablePage/FreelancersTablePage'
import NFTTablePage from 'containers/NFTTablePage/NFTTablePage'
import CDTFATablePage from 'containers/CDTFATablePage/CDTFATablePage'
import OffshoreTablePage from 'containers/OffshoreTablePage/OffshoreTablePage'
import FatcaTablePage from 'containers/FatcaTablePage/FatcaTablePage'
import ServiceProvidersTablePage from 'containers/ServiceProvidersTablePage/ServiceProvidersTablePage'
import InfluencersVerticalPage from 'containers/VerticalPage/influencers/VerticalPage/VerticalPage'
import EcommerceVerticalPage from 'containers/VerticalPage/ecommerce/VerticalPage/VerticalPage'
import EcommerceExtendedVerticalPage from 'containers/VerticalPage/ecommerce-extended/VerticalPage/VerticalPage'
import STRVerticalPage from 'containers/VerticalPage/str/VerticalPage/VerticalPage'
import FreelancersVerticalPage from 'containers/VerticalPage/freelancers/VerticalPage/VerticalPage'
import NFTVerticalPage from 'containers/VerticalPage/nft/VerticalPage/VerticalPage'
import CDTFAVerticalPage from 'containers/VerticalPage/cdtfa/VerticalPage/VerticalPage'
import CompanyOverviewVerticalPage from 'containers/VerticalPage/company-overview/VerticalPage/VerticalPage'
import ServiceProvidersVerticalPage from 'containers/VerticalPage/serviceProviders/VerticalPage/VerticalPage'
import STRTablePage from 'containers/STRTablePage/STRTablePage'
import EcommerceExtendedTablePage from 'containers/EcommerceExtendedTablePage/EcommerceExtendedTablePage'

import {
  selectAvailableZones,
  selectCurrentUser,
  selectCurrentZone,
  selectIsGlobalLoading,
  selectIsLoggingIn,
  setCurrentZone,
} from 'store/global'
import { selectIsModalDisplayed, selectIsUserPlatformsModalDisplayed } from 'store/settings'
import { setDisplayedColumns } from 'store/businesses'
import { setHostsDisplayedColumns } from 'store/rentals'
import VerticalContextProvider from 'store/verticals'

import localStorageService from 'services/storage'
import messages from './messages'
import { ROLES } from 'utils/roles'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { injectIntl } from 'react-intl'
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { globalTheme } from 'styles/globalTheme'
import styles from './App.scss'
import CompanyOverviewTablePage from 'containers/CompanyOverviewTablePage/CompanyOverviewTablePage'
import { SelectedRowsProvider } from 'store/selectedRows/SelectedRowsContext'
import ErrorBoundary from 'utils/ErrorBoundary/ErrorBoundary'
import AuthWrapper from 'components/AuthWrapper'
import LoginPage from 'containers/Login/Login'

const queryClient = new QueryClient()

function App({ intl }) {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const { pushInstruction } = useMatomo()
  const currentUser = useSelector(selectCurrentUser)
  const isGlobalLoading = useSelector(selectIsGlobalLoading)
  const isLoggingIn = useSelector(selectIsLoggingIn)
  const isSettingsModalDisplayed = useSelector(selectIsModalDisplayed)
  const isUserPlatformsModalDisplayed = useSelector(selectIsUserPlatformsModalDisplayed)
  const availableZones = useSelector(selectAvailableZones)
  const location = useLocation()
  const { pathname = '', search } = location || {}

  const [currentZoneIndexes, setCurrentZoneIndexes] = useState({})

  const currentZone = useSelector(selectCurrentZone)

  const changeCurrentZoneIndexes = newIndexes => {
    setCurrentZoneIndexes(newIndexes)
  }

  const restoreLastBusinessesDisplayedColumns = () => {
    const columns = localStorageService.displayedColumns
    const hostColumns = localStorageService.displayedSTRColumns

    if (columns) dispatch(setDisplayedColumns({ columns }))
    if (hostColumns) dispatch(setHostsDisplayedColumns({ columns: hostColumns }))
  }

  // Any other API requests that should only be performed once on initial load of the App can be added inside this method
  const onAppInitialLoad = () => {
    // Sentry.init({ dsn: process.env.SENTRY_DSN });
    const returnUrl = `${pathname}${search}`
    localStorageService.returnUrl = returnUrl

    restoreLastBusinessesDisplayedColumns()
  }

  useEffect(onAppInitialLoad, [])

  const restoreLastSelectedZone = () => {
    if (!currentUser || !currentUser.email || !availableZones.length) return

    const userSelectedZoneId = query.get('userSelectedZoneId')

    const { email, zoneId } = localStorageService.lastSelectedZoneForUser

    if (email !== currentUser.email) {
      if (availableZones?.length) dispatch(setCurrentZone({ zone: availableZones[0] }))
      return
    }

    const lastZoneId = parseInt(userSelectedZoneId || zoneId)
    const nextZone = availableZones.find(({ id }) => id === lastZoneId)

    dispatch(setCurrentZone({ zone: nextZone }))
  }

  useEffect(() => {
    restoreLastSelectedZone()
    const name = currentUser ? currentUser.email : undefined
    pushInstruction('setUserId', name)
  }, [currentUser, availableZones.length])

  const theme = createTheme(globalTheme)

  const allRoles = Object.values(ROLES)

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <div className={styles.appContainer}>
        <AuthWrapper>
          <Alerts />
          <Header currentZoneIndexes={currentZoneIndexes} />
          <Navigation />
          <main
            className={cx(
              styles.page,
              { [styles.newPage]: location.pathname.includes('/vertical') },
              styles[pathname.substring(1).split('/')[0]],
              { [styles['data-science']]: location.pathname.includes('/vertical/data-science') },
              { [styles['company-overview']]: location.pathname.includes('/vertical/company-overview') },
              {
                [styles['ecommerce-extended']]: location.pathname.includes('/vertical/ecommerce-extended'),
              },
            )}
          >
            <ErrorBoundary>
              <Switch>
                <Route exact path='/'>
                  <Redirect to='/dashboard' />
                </Route>
                <>
                  <Route allowedRoles={allRoles} exact path='/dashboard'>
                    <DashboardPage />
                  </Route>
                  <Route allowedRoles={allRoles} path='/error/:errorCode'>
                    <ErrorPage />
                  </Route>
                  <Route allowedRoles={allRoles} exact path='/preview'>
                    <Preview />
                  </Route>
                  <Route allowedRoles={allRoles} path='/management'>
                    <OrganizationsPage />
                  </Route>
                  <Route allowedRoles={allRoles} path='/profile' component={ProfilePage} />
                  <Route allowedRoles={allRoles} exact path='/businesses'>
                    <BusinessesPage />
                  </Route>
                  {currentZone?.airbnbIndices && (
                    <Route allowedRoles={allRoles} path='/rentals'>
                      <RentalsPage />
                    </Route>
                  )}
                  <Route allowedRoles={allRoles} path='/businesses/:businessId'>
                    <BusinessPage />
                  </Route>
                  <Route allowedRoles={allRoles} path='/singleBusiness/:businessId'>
                    <BusinessPage />
                  </Route>

                  <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Route allowedRoles={allRoles} path='/vertical/influencers/:entityId'>
                        <VerticalContextProvider>
                          <InfluencersVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/vertical/ecommerce/:entityId'>
                        <VerticalContextProvider>
                          <EcommerceVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/vertical/freelancers/:entityId'>
                        <VerticalContextProvider>
                          <FreelancersVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/vertical/service-providers/:entityId'>
                        <VerticalContextProvider>
                          <ServiceProvidersVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/vertical/data-science/:entityId'>
                        <VerticalContextProvider>
                          <CDTFAVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/vertical/company-overview/:caseId'>
                        <VerticalContextProvider>
                          <CompanyOverviewVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/vertical/nft/:entityId'>
                        <VerticalContextProvider>
                          <NFTVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>
                      {currentZone?.strIndices && currentZone?.strActivityIndices && (
                        <Route allowedRoles={allRoles} path='/vertical/short-term-rentals/:entityId'>
                          <VerticalContextProvider>
                            <STRVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                          </VerticalContextProvider>
                        </Route>
                      )}
                      <Route allowedRoles={allRoles} path='/vertical/ecommerce-extended/:entityId'>
                        <VerticalContextProvider>
                          <EcommerceExtendedVerticalPage changeCurrentZoneIndexes={changeCurrentZoneIndexes} />
                        </VerticalContextProvider>
                      </Route>

                      <Route allowedRoles={allRoles} path='/influencers'>
                        <SelectedRowsProvider>
                          <InfluencersTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      {currentZone?.ecommerceIndices && currentZone?.ecommerceActivityIndices && (
                        <Route allowedRoles={allRoles} path='/ecommerce'>
                          <SelectedRowsProvider>
                            <EcommerceTablePage />
                          </SelectedRowsProvider>
                        </Route>
                      )}
                      <Route allowedRoles={allRoles} path='/freelancers'>
                        <SelectedRowsProvider>
                          <FreelancersTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/service-providers'>
                        <SelectedRowsProvider>
                          <ServiceProvidersTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/nft'>
                        <SelectedRowsProvider>
                          <NFTTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/data-science'>
                        <SelectedRowsProvider>
                          <CDTFATablePage />
                        </SelectedRowsProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/offshore'>
                        <SelectedRowsProvider>
                          <OffshoreTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/company-overview'>
                        <SelectedRowsProvider>
                          <CompanyOverviewTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      <Route allowedRoles={allRoles} path='/fatca'>
                        <SelectedRowsProvider>
                          <FatcaTablePage />
                        </SelectedRowsProvider>
                      </Route>
                      {currentZone?.ecommerceExtendedIndices && currentZone?.ecommerceExtendedEmployeesIndices && (
                        <Route allowedRoles={allRoles} path='/ecommerce-extended'>
                          <SelectedRowsProvider>
                            <EcommerceExtendedTablePage />
                          </SelectedRowsProvider>
                        </Route>
                      )}
                      {currentZone?.strIndices && currentZone?.strActivityIndices && (
                        <Route allowedRoles={allRoles} path='/short-term-rentals'>
                          <SelectedRowsProvider>
                            <STRTablePage />
                          </SelectedRowsProvider>
                        </Route>
                      )}
                    </ThemeProvider>
                  </QueryClientProvider>
                </>
                <Route allowedRoles={allRoles}>
                  <NotFoundPage />
                </Route>
              </Switch>
            </ErrorBoundary>
          </main>
          {isGlobalLoading && (
            <Loader type='globalLoading' message={isLoggingIn ? intl.formatMessage(messages.loggingIn) : ''} />
          )}
          {isSettingsModalDisplayed && <SettingsModal />}
          {isUserPlatformsModalDisplayed && <PlatformsModal />}
        </AuthWrapper>
        <Switch>
          <Route path='/login'>
            <LoginPage />
          </Route>
        </Switch>
      </div>
    </QueryParamProvider>
  )
}

App.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(App)