import React from 'react'
import Icon from 'components/Icon'
import cx from 'classnames'
import styles from './ReportedStatus.scss'
import Tooltip from 'components/Tooltip'

type StatusIconMap = Record<reportedStatusValue, string>

export type reportedStatusValue =
  | 'nonFilerRed'
  | 'nonFilerOrange'
  | 'underReportedYellow'
  | 'underReportedOrange'
  | 'underReportedRed'
  | 'zeroReportedRed'
  | 'zeroReportedOrange'
  | 'properReport'
  | 'noActivity'
  | 'noFile'
  | 'underReport'
  | 'noReport'
  | 'waitingForReport'
  | 'processing'
  | 'abroadIncomeForeignCompanyReport'
  | 'abroadIncomeForeignCompanyNoReport'
  | 'abroadIncomeFalseForeignCompanyReport'
  | 'abroadIncomeFalseForeignCompanyNoReport'
  | 'foreignRegionReport'

export const statusIconMap: StatusIconMap = {
  nonFilerRed: 'nonFilerV2Red',
  nonFilerOrange: 'nonFilerV2Orange',
  underReportedYellow: 'underReportedV2Yellow',
  underReportedOrange: 'underReportedV2Orange',
  underReportedRed: 'underReportedV2Red',
  zeroReportedRed: 'zeroReportedV2Red',
  zeroReportedOrange: 'zeroReportedV2Orange',
  properReport: 'properReport',
  waitingForReport: 'waitingForReport',
  processing: 'processing',
  noActivity: 'noActivity',
  noFile: 'noFile',
  underReport: 'underReport',
  noReport: 'noReport',
  abroadIncomeForeignCompanyReport: 'abroadIncomeForeignCompanyReport',
  abroadIncomeForeignCompanyNoReport: 'abroadIncomeForeignCompanyNoReport',
  abroadIncomeFalseForeignCompanyReport: 'abroadIncomeFalseForeignCompanyReport',
  abroadIncomeFalseForeignCompanyNoReport: 'abroadIncomeFalseForeignCompanyNoReport',
  foreignRegionReport: 'foreignRegionReport',
}

interface ReportedStatusMap {
  statusIcon: string
  tooltipValue: string | null
}

const reportedStatusMap: Record<string, ReportedStatusMap> = {
  processing: {
    statusIcon: statusIconMap.processing,
    tooltipValue: `The taxpayer's return has been filed but is still pending processing in the tax authority's database.`,
  },
  nonFilerRed: {
    statusIcon: statusIconMap.nonFilerRed,
    tooltipValue: null,
  },
  nonFilerOrange: {
    statusIcon: statusIconMap.nonFilerOrange,
    tooltipValue: null,
  },
  underReportedYellow: {
    statusIcon: statusIconMap.underReportedYellow,
    tooltipValue: null,
  },
  underReportedOrange: {
    statusIcon: statusIconMap.underReportedOrange,
    tooltipValue: null,
  },
  underReportedRed: {
    statusIcon: statusIconMap.underReportedRed,
    tooltipValue: null,
  },
  zeroReportedRed: {
    statusIcon: statusIconMap.zeroReportedRed,
    tooltipValue: null,
  },
  zeroReportedOrange: {
    statusIcon: statusIconMap.zeroReportedOrange,
    tooltipValue: null,
  },
  noFile: {
    statusIcon: statusIconMap.noFile,
    tooltipValue: 'No Record Found: This taxpayer currently does not have a file in the records.',
  },
  noReport: {
    statusIcon: statusIconMap.noReport,
    tooltipValue: 'Report Unfiled: Taxpayer has a file but has not submitted a tax report for this year.',
  },
  underReport: {
    statusIcon: statusIconMap.underReport,
    tooltipValue: `Income Underreported: Taxpayer's reported income is lower than our documented figures`,
  },
  noActivity: {
    statusIcon: statusIconMap.noActivity,
    tooltipValue: `No Documented Activity: No activity recorded for the taxpayer this year, no violations detected.`,
  },
  waitingForReport: {
    statusIcon: statusIconMap.waitingForReport,
    tooltipValue: `The taxpayer is within the deadline to file a tax return or is operating under an active extension period.`,
  },
  properReport: {
    statusIcon: statusIconMap.properReport,
    tooltipValue: `Accurate Report Filed: Taxpayer has accurately reported income matching our documented records.`,
  },
  abroadIncomeForeignCompanyReport: {
    statusIcon: statusIconMap.abroadIncomeForeignCompanyReport,
    tooltipValue: `The taxpayer Reported on Income from abroad and filed a Report on Holdings in a Foreign Company (FC).`,
  },
  abroadIncomeForeignCompanyNoReport: {
    statusIcon: statusIconMap.abroadIncomeForeignCompanyNoReport,
    tooltipValue: `The taxpayer Reported on Income from abroad and didn’t file a Report on Holdings in a Foreign Company (FC).`,
  },
  abroadIncomeFalseForeignCompanyReport: {
    statusIcon: statusIconMap.abroadIncomeFalseForeignCompanyReport,
    tooltipValue: `The taxpayer didn’t Report on Income from abroad and filed a Report on Holdings in a Foreign Company.`,
  },
  abroadIncomeFalseForeignCompanyNoReport: {
    statusIcon: statusIconMap.abroadIncomeFalseForeignCompanyNoReport,
    tooltipValue: `The taxpayer didn’t Report on Income from abroad and didn't filed a Report on Holdings in a Foreign Company.`,
  },
  foreignRegionReport: {
    statusIcon: statusIconMap.foreignRegionReport,
    tooltipValue: `The taxpayer has submitted a tax return within another jusrisdiction.`,
  },
}

export type ReportedStatusType = Record<string, reportedStatusValue>

interface ReportedStatusProps {
  id: string
  reportedStatus: ReportedStatusType
}

const ReportedStatus: React.FC<ReportedStatusProps> = ({ reportedStatus, id }: ReportedStatusProps) => (
  <div className={styles.reportedStatus}>
    {Object.entries(reportedStatus)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .sort(([aKey, _1], [bKey, _2]) => parseInt(aKey) - parseInt(bKey))
      .map(([year, status]) => {
        const statusIcon = reportedStatusMap?.[status as reportedStatusValue]?.statusIcon
        const tooltipValue = reportedStatusMap?.[status as reportedStatusValue]?.tooltipValue || ''
        return (
          <Tooltip key={`${year}-${id}`} title={tooltipValue}>
            <div className={styles.inner}>
              <span className={styles.title}>{year}</span>
              <Icon name={statusIcon} size='regular' className={cx(styles.value)} />
            </div>
          </Tooltip>
        )
      })}
  </div>
)

export default ReportedStatus
