import { ReactNode } from 'react'
import { FinancialBarChartData, GraphData, Period, PieChartData, BaseTotalsForTable } from 'utils/types'

import { Location as MapLocation } from '../../components/LeafletMap/LeafletMap'

// Enums

import { ReportedStatusType } from '../../components/ReportedStatus/ReportedStatus'

export enum PLATFORMS {
  YOUTUBE = 'youtube',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  FACEBOOK = 'facebook',
  BLOG = 'blog',
  WEBSITE = 'website',
  OTHER = 'other',
  SHOPIFY = 'shopify',
}

export interface Associates {
  id: string
  name: string
  image: string
}

export enum ACTIVITY_OVERVIEW {
  ACTIVITY_TYPE = 'activityType',
  ACTIVE_VERTICALS = 'activeVerticals',
  FIRST_ACTIVITY = 'firstActivity',
  DOCUMENTED_REVENUE = 'documentedRevenue',
  REPORTED_REVENUE = 'reportedRevenue',
}

// Verticals Types

// Vertical Entity
export interface EntityDetails {
  tin: string
  name: string
  image: string
  profileUrls: string[]
  location: string
  birthday: string
  reportedStatus: ReportedStatusType
  matchBy?: string
  collectsSalesTax?: number
}

export interface ISummary {
  accountNumber?: string
  controlPersonType?: string
  controlledEntity?: string
  bankName?: string
  jurisdiction?: string
}

export interface IRegistryIds {
  key: string
  value: string
}

export interface IRegistry {
  ids: IRegistryIds[]
  addresses: {
    key: string
    value: string
  }[]
  names: {
    key: 'account_name' | 'dba_name' | 'customer_name'
    value: string
  }[]
  status: 'Registered' | 'Unregistered'
  startDate: Date
  endDate: Date
}

export interface ISource {
  platform: string
  uri: string
}

export interface OverviewDetails {
  activityType: string
  activeVerticals: string[]
  firstActivity: string
  lastActivity: string
  documentedRevenue: number | null
  reportedRevenue: number | null
  taxGap: number | null
  totalRevenue: number | null
  roles?: string[]
  name?: string
  entities?: string[]
  about?: string
  localJurisdiction?: string
  summary?: ISummary[]
  unreportedRevenue?: number
  docRefArs?: string[]
  locations?: MapLocation[]
  cartCheckStatus?: string
  magnitude?: {
    score: number
    numberOfShops?: number
    rank?: number
    totalTraffic3Months?: number
    averageLocalTrafficMonthly?: number
    averageTrafficMonthly?: number
  }
  risk?: {
    physicalNexus?: { business?: boolean; employees?: boolean }
    score: number
    registrationStatus: string
  }
  registry?: IRegistry[]
  businessNames?: {
    name: string
    isRelatedCompany: boolean
    sources: ISource[]
  }[]
  shopsCategories?: string[]
  profitLoss?: number
}

export interface ContactInfo {
  emails: string[]
  weblinks: Record<string, string[]>
  phones: string[]
  addresses: string[]
  wallets?: string[]
}

interface InfluencersRevenueSourceOverview {
  followers?: number | null
  media?: number | null
  posts?: number | null
  friends?: number | null
}

interface EcomRevenueSourceOverview {
  itemsSold?: number | null
  reviews?: number | null
}

export interface CartCheckProduct {
  productLink: string
  title: string
}
interface EcomSalesTaxRevenueSourceOverview {
  cartCheck?: { status: string; products: CartCheckProduct[] }
}
export interface RevenueSourceOverview
  extends InfluencersRevenueSourceOverview,
    EcomRevenueSourceOverview,
    EcomSalesTaxRevenueSourceOverview {
  totalRevenue: number | null
  firstActivity: string
}

interface RevenueByYearKey {
  [year: string]: number
}
export interface RevenueByYear {
  total: RevenueByYearKey
  exact: RevenueByYearKey
  documented: RevenueByYearKey
  extrapolated: RevenueByYearKey
  reportedByYear: RevenueByYearKey
  unReportedByYear: RevenueByYearKey
  totalWithoutVat: RevenueByYearKey
}

export interface ListingsLocations {
  listingId: number | string
  coordinates: string
  listingAddressEnriched: string
  listingStateLocator: string
}

export interface RevenueSource {
  platform: PLATFORMS
  profileImageUrl: string
  profileName: string
  profileUrl: string
  overview: RevenueSourceOverview
  revenueByYear?: RevenueByYear
  associates?: Associates[]
  businessName?: string
  listingsLocations?: ListingsLocations
}

export interface RevenueSourceSummary {
  platform: PLATFORMS
  count: number
}

export interface IDetailsSources {
  platform: string
  url: string
  name: string
  overview: {
    businessType: string
    businessCategories?: string[] | null
    attributes?: string[] | null
    priceRange?: number | null
    totalReviews?: number | null
    firstActivity?: string | null
    lastActivity?: string | null
  }
}

export interface AggregatedTotals {
  totalSales: number
  salesTaxesPaid: number
  adjustedTaxCdtfa: number
  adjustedTaxIvix: number
  grossSales: number
  taxableSales: number
  salesTaxesDue: number
  salesTaxesLeftToPay: number
  qtrYieldCdtfa: number
  cdtfaTaxReportCorrect: boolean
  qtrYieldIvix: number
  underReported: boolean
  overReported: boolean
  naicsChanged: boolean
  customerType: string
  taxDistrict: string
  siteStartDate: string
  siteEndDate: string
  numYearsWithQtrYield: number
  numSitesPerAccount: number
  accountSizeBySales: string
  cdtfaIsClosed: boolean
  totalAdjustedOfTotalDue: number
  isMatched: boolean
  postCeasedWebActivity: boolean
  siteStatus: string
  firstActivity: number
}

export interface VerticalEntity {
  id: string
  entityDetails: EntityDetails
  overviewDetails: OverviewDetails
  contactInfo: ContactInfo
  revenueSources: RevenueSource[]
  totals: BaseTotalsForTable
  caseId?: string
  detailsSources?: IDetailsSources[]
  aggregatedTotals?: AggregatedTotals
  specialities?: any[]
  siteId?: string
  taxOfficeCivil?: string
  firstTaxReturn?: string
  lastTaxReturn?: string
  taxCreditsAndIncentives?: string
}

// Vertical Activity
export interface VerticalDocumentActivity {
  id: string
  entityId: string
  activityDate: string
  activityHeadline: string | null
  activityPhoto: string
  activityText: string
  activityType: string
  activityUrl: string
  activityUser: string
  activityUserPhoto: string
  index: string
  activitySource: PLATFORMS
  revenue: number | null
  revenueMax: number | null
  revenueSource: string
  score: number
  type: string
  version: number
  activityFrom?: string
  activityTo?: string
  revenueOtherCoin?: number
  otherCoin?: string
  activityItem?: string
  profitLoss?: number
}

export interface VerticalDocumentActivityStatsDatum {
  revenueSource: string
  platform: PLATFORMS | null
  count: number | null
  revenueValue: number
}

export interface VerticalDocumentActivityStatsActivityType {
  key: string
  docCount: number
  totalRevenueSum: { value: number }
}

export type VerticalDocumentActivityStats = {
  activitySources: VerticalDocumentActivityStatsDatum[]
  activityTypes: VerticalDocumentActivityStatsActivityType[]
  extraAggregations?: null
}

// Context types
export interface VerticalsState {
  verticalEntity: VerticalEntity | null
  verticalDocumentActivityStats:
    | VerticalDocumentActivityStats
    | { activitySources: null; activityTypes: null; extraAggregations?: null }
  verticalRevenueBySources: PieChartData[] | null
  verticalEstimatedRevenue: PieChartData[] | null
  verticalReportedRevenue: FinancialBarChartData[] | null
  graphData: GraphData
  isCollapsedRevenuesCarousel: boolean
}

//TODO: fix return types
export interface IVerticalContext extends VerticalsState {
  getVerticalEntity: (entityId: string, zoneEndpoint: string) => Promise<null | undefined>
  getVerticalDocumentActivityStats: (
    entityId: string,
    zoneEndpointActivity: string,
    zoneEndpointEntity: string,
    period?: Period,
    activityType?: string | string[],
    filters?: string,
    extraAggregationField?: string,
  ) => Promise<null | undefined>
  getVerticalPieChartsData: (entityId: string, period: Period, zoneEndpoint: string) => Promise<null | undefined>
  getVerticalBarChartsData: (entityId: string, period: Period, zoneEndpoint: string) => Promise<null | undefined>
  getGraphData: (entityId: string, zoneEndpoint: string) => Promise<null | undefined>
  toggleRevenuesCarousel: () => void
}

export interface VerticalsProviderProps {
  children: ReactNode
}

export enum VerticalQueryParams {
  source = 'api',
}
