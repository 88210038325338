import React from 'react'
import Income from 'components/Income'
import TitledData from 'components/common/TitledData'
import CardContent from '@mui/material/CardContent'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import GenericText from 'components/common/GenericText'
import GenericHeading from 'components/common/GenericHeading'
import GenericCard from 'components/common/GenericCard'
import GenericCardHeader from 'components/common/GenericCardHeader'
import { originalSourceToImageMap } from 'components/BusinessSource/specs'
import { OverviewDetails } from 'store/verticals/types'
import { formatDate } from './utils'
import { NOT_AVAILABLE } from 'utils/constants'
import styles from './ActivityOverviewCard.scss'

interface ActivityOverviewProps {
  overviewData: OverviewDetails
  documentedBuys?: number
}

const ActivityOverview = ({ overviewData, documentedBuys }: ActivityOverviewProps) => {
  const theme = useTheme()

  const titleSx = {
    fontSize: { md: 14, lg: 14, xl: 15, xxl: 20, xxxl: 28 },
    lineHeight: { md: '13px', lg: '14px', xl: '18px', xxl: '24px', xxxl: '26px' },
    minHeight: { md: '28px', lg: '28px', xl: 'auto', xxl: '40px', xxxl: '45px' },
    textAlign: 'start',
    color: '#707991',
  }

  const valuesSx = {
    fontSize: { md: '14px', lg: '16px', xl: '22px', xxl: '24px', xxxl: '36px' },
    lineHeight: { md: '16px', lg: '18px', xl: '28px', xxl: '34px', xxx: '40px' },
    color: '#1C2842',
    fontFamily: 'Dosis',
    fontWeight: 500,
    textTransform: 'capitalize',
  }

  const dataSx = {
    maxWidth: { lg: 100, xl: 124, xxxl: 200 },
    textAlign: 'start',
  }

  return (
    <GenericCard sx={{ gridColumn: '2 / 3', display: 'flex', flexDirection: 'column' }}>
      <GenericCardHeader
        sx={{ bgcolor: theme.palette.secondary.main }}
        title={
          <GenericHeading
            sx={{
              color: '#fff',
              fontSize: { md: '16px', lg: '18px', xl: '20px', xxl: '26px', xxxl: '36px' },
              p: '16px',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            ACTIVITY OVERVIEW
          </GenericHeading>
        }
      />
      <CardContent
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1,
          p: { md: '8px 10px', lg: '12px 16px', xl: '15px 27px', xxl: '22px 30px', xxxl: '26px 34px' },
        }}
      >
        <Stack direction='row' sx={{ justifyContent: 'space-between', p: '0', gap: '10px', flexGrow: 1 }}>
          <TitledData
            title='Activity Type'
            data={<GenericText sx={{ ...valuesSx }}>{overviewData.activityType}</GenericText>}
            titleSx={titleSx}
            key='Activity Type'
            dataSx={dataSx}
          />
          <TitledData
            title='Active Verticals'
            data={
              <Stack direction='row' spacing={1}>
                {overviewData.activeVerticals.map(vertical => (
                  <img
                    src={originalSourceToImageMap[vertical]}
                    alt={vertical}
                    key={vertical}
                    className={styles.verticalIcon}
                  />
                ))}
              </Stack>
            }
            titleSx={titleSx}
            key='Active Verticals'
            dataSx={dataSx}
          />
          <TitledData
            title='First Activity'
            data={<GenericText sx={{ ...valuesSx }}>{formatDate(overviewData.firstActivity)}</GenericText>}
            titleSx={titleSx}
            key='First Activity'
            dataSx={dataSx}
          />
          <TitledData
            title={overviewData.profitLoss ? 'Gain/Loss (USD)' : 'Total Revenue'}
            data={
              <Box sx={{ ...valuesSx, fontWeight: 600 }}>
                {overviewData.profitLoss ? (
                  <Income value={overviewData.profitLoss} />
                ) : overviewData.documentedRevenue ? (
                  <Income value={overviewData.documentedRevenue - (documentedBuys ?? 0)} />
                ) : (
                  NOT_AVAILABLE
                )}
              </Box>
            }
            titleSx={titleSx}
            key={overviewData.profitLoss ? 'Gain/Loss (USD)' : 'Total Revenue'}
            dataSx={dataSx}
          />
          <TitledData
            title='Reported Revenue'
            data={
              <Box sx={{ ...valuesSx, fontWeight: 600 }}>
                {overviewData.reportedRevenue ? <Income value={overviewData.reportedRevenue} /> : NOT_AVAILABLE}
              </Box>
            }
            titleSx={titleSx}
            key='Reported Revenue'
            dataSx={dataSx}
          />
          <TitledData
            title='Unreported Revenue'
            data={
              <Box sx={{ ...valuesSx, fontWeight: 600 }}>
                {overviewData.taxGap ? <Income value={overviewData.taxGap} /> : NOT_AVAILABLE}
              </Box>
            }
            titleSx={titleSx}
            key='Unreported Revenue'
            dataSx={dataSx}
          />
        </Stack>
      </CardContent>
    </GenericCard>
  )
}

export default ActivityOverview
