import { FocusEvent, MouseEvent } from 'react'
import moment from 'moment'
import type { DropdownOption } from 'store/verticalsDetails/types'
import { Period } from 'utils/types'
import { ContactInfoSections } from 'containers/VerticalPage/common/types'

export type changeCurrentZoneIndexesFunc = (indexes: {}) => void
export type HandleOptionClickFunc = (event: MouseEvent<HTMLElement>, optionIndex: number) => void
export type ToggleDropdownFunc = (
  e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement> | FocusEvent<HTMLDivElement>,
) => void

export const getDateByYearsLater = (yearsLater: number, fromDate?: string): string => {
  const currentDate = fromDate ? new Date(fromDate) : new Date()
  const currentYear = currentDate.getFullYear()
  const yearsLaterDate = currentYear + yearsLater
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
  const day = ('0' + currentDate.getDate()).slice(-2)

  return `${yearsLaterDate}-${month}-${day}`
}

export const getDefaultDateValues = (year?: string) => {
  return {
    start: moment(year || getDateByYearsLater(-10)).format('YYYY-MM-DD'),
    end: moment(getDateByYearsLater(-1)).format('YYYY-MM-DD'),
  }
}

export const getHeadingValues = (options: DropdownOption[]) => {
  let text = ''
  let revenue = 0
  let activities = 0

  const isAllOptionsUnchecked = options.every(option => !option?.isChecked)
  if (isAllOptionsUnchecked) {
    text = 'REVENUE SOURCE: ALL'
  } else {
    const selectedOptions = options.filter(option => option?.isChecked)
    if (selectedOptions.length > 1) {
      text = `${selectedOptions.length} REVENUE SOURCES`
    } else {
      text = selectedOptions[0]?.revenueSource || ''
    }
  }

  options.forEach(option => {
    if (isAllOptionsUnchecked) {
      activities += option?.count || 0
      revenue += option?.revenueValue || 0
    } else if (option.isChecked) {
      activities += option?.count || 0
      revenue += option?.revenueValue || 0
    }
  })

  return {
    text,
    revenue,
    activities,
  }
}

export const getUrlPageValues = (): string[] => window.location.pathname.split('/').splice(1)

export const byYearValueIsExist = (
  byYear: {
    [key: string]: {
      [key: number]: string
    }
  },
  key: string,
) => {
  return byYear?.[key] && typeof byYear?.[key] === 'object' && Object.keys(byYear)?.length > 0
}

export const numberIsBetween = (value: number, start: number, end: number) => value >= start && value <= end

export const getYearFromPeriod = (period: Period) => {
  const startYear = moment(period.start).year()
  const endYear = moment(period.end).year()

  return {
    startYear,
    endYear,
  }
}

export const removeKeywordSuffix = (arr: string[]): string[] => {
  return arr.map(item => {
    if (item.endsWith('.keyword')) {
      return item.slice(0, -8)
    }
    return item
  })
}

export const contactInfoSections: Array<keyof typeof ContactInfoSections> = [
  'weblinks',
  'phones',
  'emails',
  'addresses',
  'wallets',
]

export const defaultWebsiteIcon = 'default_website'

export const normalizeUrlProtocol = (url: string) => {
  if (url.startsWith('http://') || url.startsWith('https://')) return url
  return '//' + url
}
