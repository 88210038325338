import { saveAs } from 'file-saver'

export const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = window.atob(base64)
  const length = binaryString.length
  const bytes = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export const downloadXLSX = async (base64Data: string, fileName: string) => {
  try {
    const arrayBuffer = base64ToArrayBuffer(base64Data)

    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, fileName)
  } catch (error) {
    console.error('Error downloading XLSX:', error)
  }
}
