import React, { useEffect } from 'react'
import Content from '../Content/Content'
import VerticalDetailsContextProvider from 'store/verticalsDetails/Context'
import Details from 'containers/VerticalPage/nft/Details/Details'
import VerticalPageWrapper from 'containers/VerticalPage/common/VerticalPageWrapper/VerticalPageWrapper'
import { CurrentZone } from 'utils/types'
import { useSelector } from 'react-redux'
import { selectCurrentZone } from 'store/global'
import { changeCurrentZoneIndexesFunc } from 'containers/VerticalPage/common/utils'

const VerticalPage = (props: { changeCurrentZoneIndexes: changeCurrentZoneIndexesFunc }) => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  useEffect(() => {
    props.changeCurrentZoneIndexes({
      baseIndices: currentZone?.nftIndices,
      activityIndices: currentZone?.nftActivityIndices,
    })
  }, [currentZone])

  return (
    <VerticalPageWrapper
      gridContainerSx={{
        gridTemplateColumns: '100%',
      }}
    >
      <Content />
      <VerticalDetailsContextProvider>
        <Details />
      </VerticalDetailsContextProvider>
    </VerticalPageWrapper>
  )
}

export default VerticalPage
