import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VerticalContext } from 'store/verticals/Context'
import GenericText from 'components/common/GenericText'
import VerticalBAHeading from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBAHeading'
import VerticalBARevenueSource from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenueSource'
import VerticalBARevenueSourceDetails from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenueSourceDetails'
import VerticalBARevenue from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenue'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import { VerticalBusinessActivityLoader, VerticalTabsHeadingLoader } from '../../common/Loaders'
import ActivityDropdown from 'containers/VerticalPage/common/ActivityDropdown/ActivityDropdown'
import GenericHeading from 'components/common/GenericHeading'
import Income from 'components/Income'
import DatePicker from 'components/common/DatePicker'
import { selectCurrentZone } from 'store/global'
import type { CurrentZone } from 'utils/types'
import { getUserKey, getActivityDateKey } from './utils'
import { getHeadingValues, getDefaultDateValues } from 'containers/VerticalPage/common/utils'
import { VerticalsDetailsContext } from 'store/verticalsDetails/Context'
import { activityTypes } from '../Details/utils'
import { useDebounce } from 'hooks/useDebounce'
import { Waypoint } from 'react-waypoint'
import Loader from 'components/Loader/Loader'
import { useDocumentActivitiesQuery } from 'containers/VerticalPage/common/useDocumentActivitiesQuery'
import { usePeriodSetter } from 'containers/VerticalPage/common/hooks'
import styles from '../../common/styles.scss'
import ActivityExportButton from 'containers/VerticalPage/common/ActivityExportButton/ActivityExportButton'
import { onActivityCSVExport } from 'utils/activities'

const calendarIconUrl = require('components/common/images/calendar.svg')

interface ActivityProps {
  activityType?: string
}

const periodFormat = 'MMM. yyyy'
const periodPopperPlacement = 'bottom-end'
const PAGE_SIZE = 100

const Activity: FC<ActivityProps> = ({ activityType }) => {
  const { getVerticalDocumentActivityStats } = useContext(VerticalContext)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const {
    detailsFilters,
    submittedDropdownOptions,
    setDetailsDropdownOptions,
    setSubmittedDropdownOptions,
    setDetailsFilters,
  } = useContext(VerticalsDetailsContext)
  const theme = useTheme()
  const defaultDateValues = getDefaultDateValues()

  const { period: datePeriod, handlePeriodChange: handleBusinessActivityPeriodChange } =
    usePeriodSetter(defaultDateValues)

  const { entityId } = useParams<{ entityId: string }>()
  const { params: { vertical = '' } = {} } = useRouteMatch<{ vertical: string }>('/vertical/:vertical') || {}

  const userKey = useMemo(() => getUserKey(activityType), [activityType])
  const activityDateKey = useMemo(() => getActivityDateKey(activityType), [activityType])

  const datePeriodDebounced = useDebounce(datePeriod, 1000)
  const activityTypeDebounced = useDebounce(activityType, 1000)

  const currentPeriod = useMemo(
    () => (activityType !== activityTypes.SOLD_ITEM ? datePeriod : undefined),
    [activityType, datePeriod],
  )

  const sort = useMemo(() => (activityType === activityTypes.REVIEW ? [{ activityDate: 'desc' }] : []), [activityType])

  const { verticalDocumentActivity, isFetching, isLoading, fetchNextPage } = useDocumentActivitiesQuery({
    entityId,
    detailsFilters,
    zoneActivityIndices: currentZone.ecommerceActivityIndices,
    currentPeriod,
    pageSize: PAGE_SIZE,
    activityType,
    sort,
  })

  useEffect(() => {
    if (!entityId || !currentZone) return
    const fetchDocumentActivityStats = async () => {
      await getVerticalDocumentActivityStats(
        entityId,
        currentZone.ecommerceActivityIndices,
        currentZone.ecommerceIndices,
        activityType !== activityTypes.SOLD_ITEM ? datePeriod : undefined,
        activityType,
      )
    }

    fetchDocumentActivityStats()
  }, [
    entityId,
    datePeriodDebounced,
    currentZone,
    activityTypeDebounced,
    datePeriod,
    activityType,
    getVerticalDocumentActivityStats,
  ])

  useEffect(() => {
    setDetailsDropdownOptions([])
    setSubmittedDropdownOptions([])
    setDetailsFilters([])
  }, [activityType])

  const headingValues = useMemo(() => getHeadingValues(submittedDropdownOptions), [submittedDropdownOptions])

  const handleEnterWaypoint = async () => fetchNextPage()

  const shouldRenderWaypoint = useCallback(
    (index: number) => {
      if (!verticalDocumentActivity) return false
      const hasMoreToFetch = verticalDocumentActivity.length >= PAGE_SIZE
      const isAtLastFetchedItem = index === verticalDocumentActivity.length - 1
      return hasMoreToFetch && isAtLastFetchedItem
    },
    [verticalDocumentActivity],
  )

  const handleExport = () => {
    const formattedFilters = JSON.stringify([
      { activityType: activityType },
      ...submittedDropdownOptions.filter(item => item.isChecked).map(item => ({ revenueSource: item.revenueSource })),
    ])

    onActivityCSVExport(
      currentZone.ecommerceActivityIndices,
      entityId,
      vertical,
      formattedFilters,
      activityType === activityTypes.SOLD_ITEM ? undefined : datePeriod,
      'EcommerceActivity.csv',
    )
  }

  return (
    <Box
      sx={{
        bgcolor: 'white',
        gridRow: '2 / 3',
        overflow: 'hidden',
        display: 'grid',
        padding: '16px 0px 8px 12px',
        borderRadius: '16px',
      }}
    >
      {isLoading ? (
        <VerticalTabsHeadingLoader />
      ) : (
        <VerticalBAHeading
          customSx={{
            paddingInlineStart: '12px',
            paddingInlineEnd: '12px',
            pb: '3px',
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={{ lg: 1, xxxl: 3 }}>
              <GenericHeading
                sx={{
                  fontSize: { md: 14, lg: 16, xl: 18, xxl: 34, xxxl: 44 },
                  fontWeight: 600,
                }}
              >
                {headingValues.text}
              </GenericHeading>
              <Stack
                direction='row'
                alignItems='center'
                sx={{
                  fontFamily: 'Dosis',
                  fontSize: { md: 14, lg: 16, xl: 18, xxl: 34, xxxl: 44 },
                  fontWeight: 500,
                }}
                className={styles.headingValuesContainer}
              >
                <Income value={headingValues.revenue} className={styles.income} />
                {`REVENUE, ${headingValues.activities} ACTIVITIES`}
              </Stack>
              <ActivityDropdown />
            </Stack>
            <div className={styles.actions}>
              <ActivityExportButton onExport={handleExport} />

              {activityType !== activityTypes.SOLD_ITEM && (
                <DatePicker
                  periodStart={datePeriod.start}
                  periodEnd={datePeriod.end}
                  handlePeriodChange={handleBusinessActivityPeriodChange}
                  format={periodFormat}
                  popperPlacement={periodPopperPlacement}
                  isCharts
                  iconUrl={calendarIconUrl}
                />
              )}
            </div>
          </Stack>
        </VerticalBAHeading>
      )}

      <Stack
        sx={{
          overflow: isLoading ? 'hidden' : 'auto',
          pt: '4px',
          ...theme.mixins.customScrollBar(),
        }}
        pl='12px'
      >
        {isLoading ? (
          <>
            <VerticalBusinessActivityLoader />
            <VerticalBusinessActivityLoader />
            <VerticalBusinessActivityLoader />
          </>
        ) : verticalDocumentActivity?.length ? (
          <Box position='relative'>
            {verticalDocumentActivity?.map((activity, index) => (
              <Stack
                key={activity.id}
                direction='row'
                sx={{
                  borderBlockEnd: index === verticalDocumentActivity.length - 1 ? 'none' : '1px solid #DFDFDF',
                }}
              >
                <Box
                  minWidth={{ md: 150, lg: 200, xl: 200, xxl: 300, xxxl: 400 }}
                  pl={{ md: 0, lg: 1, xl: 2, xxl: 2, xxxl: 2 }}
                  flexBasis={1}
                >
                  <VerticalBARevenueSource
                    hideUserImage={activityType === activityTypes.SOLD_ITEM}
                    user={activity[userKey]}
                    userPhoto={activity.activityUserPhoto}
                    activityType={activity.revenueSource}
                    activityUrl={activity.activityUrl}
                    platform={activity.activitySource}
                    activityDate={activityDateKey ? activity[activityDateKey] : null}
                  />
                </Box>

                <Divider orientation='vertical' flexItem />

                <Box flex={1}>
                  <VerticalBARevenueSourceDetails
                    headline={activity?.activityHeadline}
                    text={activity.activityText}
                    photoUrl={activity.activityPhoto}
                  />
                </Box>

                <Divider orientation='vertical' flexItem />

                <Box
                  minWidth={{ lg: 100, xxxl: 220 }}
                  ml={{ lg: 2, xxxl: 4 }}
                  flexBasis={1}
                  sx={{
                    [theme.breakpoints.down('xl')]: {
                      minWidth: '100px',
                      ml: 2,
                    },
                  }}
                >
                  <VerticalBARevenue revenue={activity.revenue} />
                </Box>
                {shouldRenderWaypoint(index) && <Waypoint onEnter={handleEnterWaypoint} />}
              </Stack>
            ))}
            {isFetching && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '20px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <Loader type='scrolling' />
              </Box>
            )}
          </Box>
        ) : (
          <GenericText
            sx={{
              fontSize: { lg: 24, xxxl: 48 },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              [theme.breakpoints.down('xl')]: {
                fontSize: '24px',
              },
            }}
          >
            No Activity
          </GenericText>
        )}
      </Stack>
    </Box>
  )
}

export default Activity
