// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivityExportButton-module__container--s7LNn{margin-right:12px}.ActivityExportButton-module__container--s7LNn .ActivityExportButton-module__share--T2ASQ{width:32px;height:32px;display:flex;align-items:center;justify-content:center;margin-left:auto}.ActivityExportButton-module__container--s7LNn .ActivityExportButton-module__share--T2ASQ:disabled{height:35px}.ActivityExportButton-module__container--s7LNn .ActivityExportButton-module__share--T2ASQ:disabled svg{overflow:hidden;animation:ActivityExportButton-module__rotation--_20e6 6s infinite linear}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/common/ActivityExportButton/ActivityExportButton.module.scss"],"names":[],"mappings":"AAAA,+CACE,iBAAA,CACA,0FACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,mGAKE,WAAA,CAJA,uGACE,eAAA,CACA,yEAAA","sourcesContent":[".container {\n  margin-right: 12px;\n  .share {\n    width: 32px;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: auto;\n    &:disabled {\n      svg {\n        overflow: hidden;\n        animation: rotation 6s infinite linear;\n      }\n      height: 35px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ActivityExportButton-module__container--s7LNn",
	"share": "ActivityExportButton-module__share--T2ASQ",
	"rotation": "ActivityExportButton-module__rotation--_20e6"
};
export default ___CSS_LOADER_EXPORT___;
