import React from 'react'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import Icon from 'components/Icon'
import styles from './ActivityExportButton.module.scss'

interface IActivityExportButton {
  onExport: () => void
}

const ActivityExportButton = ({ onExport }: IActivityExportButton) => {
  return (
    <div className={styles.container}>
      <Tooltip title='Export Entity Activity CSV'>
        <Button color='light' outline className={styles.share} onClick={onExport}>
          <Icon name='share' size='regular' />
        </Button>
      </Tooltip>
    </div>
  )
}

export default ActivityExportButton
