import { EdgeDefinition, NodeDefinition } from 'cytoscape'
import { VerticalEntity } from 'store/verticals/types'

export type Nullable<T> = { [K in keyof T]: T[K] | null }

export interface PieChartData {
  name: string
  value: number
}

export interface GraphData {
  nodes: NodeDefinition[]
  edges: EdgeDefinition[]
}

export type FinancialBarChartData = Record<string, string | number | null>

export interface Period {
  start: string
  end: string
}

export type CurrentZone = Record<string, string>

export interface BaseTotalsForTable {
  firstActivity: string
  documentedRevenue: number
  estimatedRevenue: number
  totalRevenue: number
  reportedRevenue: number
  documentedBuys: number
  taxGap: number
  byYear: {
    exact: { [key: number]: string }
    extrapolated: { [key: number]: string }
    reportedByYear: { [key: number]: string }
    total: { [key: number]: string }
    documented: { [key: number]: string }
    totalWithoutVat: { [key: number]: string }
    unReportedByYear: { [key: number]: string }
    totalTaxesPaidByCDTFA: { [key: number]: string }
    totalTaxesPaidByIvix: { [key: number]: string }
  }
  quarterlySalesTaxesDue: { [key: number]: string }
  quarterlyAdjustedTaxCdtfa: { [key: number]: string }
  quarterlyAdjustedTaxIvix: { [key: number]: string }
  hostName?: string
  activeListings?: number
  totalListings?: number
  profitLoss?: number
}

export interface BaseEntityForTable extends VerticalEntity {
  totals: BaseTotalsForTable
  caseId?: string
}

export interface BaseTableOverview {
  total: number
  summary: {
    entitiesCount: number
    incomeCounts: { documented: number; estimated: number; total: number; profitLoss?: number }
    platforms: { source: string; count: number }[]
  }
}

export enum TABLE_TEXT_TYPES {
  default = 'default',
  columnHeader = 'columnHeader',
  groupHeader = 'groupHeader',
}
